/*
 |--------------------------------------------------------------------------
 | TailwindCSS Directives
 |--------------------------------------------------------------------------
 |
 | Import TailwindCSS directives and swipe out at build-time with all of
 | the styles it generates based on your configured design system.
 |
 | Please check this link for more details:
 | https://tailwindcss.com/docs/installation#include-tailwind-in-your-css
 |
 */
@import "./_tailwind.css";

/*
    |--------------------------------------------------------------------------
    | Fonts
    |--------------------------------------------------------------------------
    |
    | Import all fonts used in the template, the font configuration can be
    | seen in "tailwind.config.js".
    |
    | Please check this link for more details:
    | https://tailwindcss.com/docs/theme
    |
    */
@import "./_roboto.css";

/*
   |--------------------------------------------------------------------------
   | Components
   |--------------------------------------------------------------------------
   |
   | Import CSS components.
   |
   */
@import "./components/_scroll.css";

/* @layer base { */
.input::-webkit-autofill {
  background-color: black !important;
}

/* } */

#root {
  height: 100%;
}

.spin-button-none::-webkit-outer-spin-button,
.spin-button-none::-webkit-inner-spin-button {
  appearance: none;
}

.second-line-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.background {
  background: radial-gradient(circle at left top, #f2f7f8, #cad3d4);
}

.spin {
  animation: spin 1.5s linear 0s infinite;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
