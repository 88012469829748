@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --base: 255 255 255;
    --color-primary: 2 88 103;
    --color-primary-soft: 44 110 119;
    --color-surface: 220 20% 97.06%;
  }

  * {
    @apply transition-colors duration-500 ease-in-out;
  }

  html,
  body {
    font-family: Roboto Flex;
    height: 100%;
  }
}

.dark {
  --base: 23 27 33;
  --color-primary: 52 58 66;
  --color-primary-soft: 44 110 119;
  --color-surface: 150 2.44% 16.08%;
}
